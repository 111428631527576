import Vue from 'vue';
import Vuex from 'vuex';
import { initialState as state } from '@/app/store/state/state';
import actions from '@/app/store/actions/actions';
import mutations from '@/app/store/mutations/mutations';
import getters from '@/app/store/getters/getters';
import { IState } from '@/app/models';

Vue.use(Vuex);

export default new Vuex.Store<IState>({
  state,
  mutations,
  actions,
  getters,
});
