import Vue from 'vue';
import App from './views/App.vue';
import router from './router';
import store from './store';
import './config/ant.design';
import './config/match-media-queries';
import './config/touch-events';
import { Auth0Plugin } from './auth';
import { FormModel } from 'ant-design-vue';
import config from '@/app/config/auth_config';
const { domain, clientId, audience } = config;

Vue.config.productionTip = false;

Vue.use(FormModel);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      )
      .then();
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
