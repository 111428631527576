const TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION = 'TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION';
const GET_CALCULATION_HOUSE_PRICE_MUTATION = 'GET_CALCULATION_HOUSE_PRICE_MUTATION';
const GET_USER_HOUSING_MUTATION = 'GET_USER_HOUSING_MUTATION';
const GET_USER_HOUSING_LOADED_MUTATION = 'GET_USER_HOUSING_LOADED_MUTATION';
const GET_USER_METADATA_MUTATION = 'GET_USER_METADATA_MUTATION';
const SET_USER_LOADED_MUTATION = 'SET_USER_LOADED_MUTATION';
const SET_USER_CLAIMS_MUTATION = 'SET_USER_CLAIMS_MUTATION';
const GET_USER_TOKEN_MUTATION = 'GET_USER_TOKEN_MUTATION';
const SET_USER_INVESTMENTS_PRODUCTS_MUTATION = 'SET_USER_INVESTMENTS_PRODUCTS_MUTATION';
const GET_INVESTMENT_PRODUCTS_MUTATION = 'GET_INVESTMENT_PRODUCTS_MUTATION';
const SET_MORTGAGE_ESTIMATES_MUTATION = 'SET_MORTGAGE_ESTIMATES_MUTATION';

export {
  TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION,
  GET_CALCULATION_HOUSE_PRICE_MUTATION,
  GET_USER_HOUSING_MUTATION,
  GET_USER_HOUSING_LOADED_MUTATION,
  GET_USER_METADATA_MUTATION,
  SET_USER_LOADED_MUTATION,
  SET_USER_CLAIMS_MUTATION,
  GET_USER_TOKEN_MUTATION,
  SET_USER_INVESTMENTS_PRODUCTS_MUTATION,
  GET_INVESTMENT_PRODUCTS_MUTATION,
  SET_MORTGAGE_ESTIMATES_MUTATION,
};
