const TOGGLE_MOBILE_MENU_COLLAPSE_ACTION = 'TOGGLE_MOBILE_MENU_COLLAPSE_ACTION';
const GET_CALCULATION_HOUSE_PRICE_ACTION = 'GET_CALCULATION_HOUSE_PRICE_ACTION';
const GET_USER_HOUSING_ACTION = 'GET_USER_HOUSING_ACTION';
const GET_USER_METADATA_ACTION = 'GET_USER_METADATA_ACTION';
const SET_USER_LOADED_ACTION = 'SET_USER_LOADED_ACTION';
const SET_USER_METADATA_ACTION = 'SET_USER_METADATA_ACTION';
const SET_USER_CLAIMS_ACTION = 'SET_USER_CLAIMS_ACTION';
const GET_USER_TOKEN_ACTION = 'GET_USER_TOKEN_ACTION';
const CLOSE_ACCOUNT_ACTION = 'CLOSE_ACCOUNT_ACTION';
const GET_USER_INVESTMENT_PRODUCTS_ACTION = 'GET_USER_INVESTMENT_PRODUCTS_ACTION';
const GET_INVESTMENT_PRODUCTS_ACTION = 'GET_INVESTMENT_PRODUCTS_ACTION';
const ADD_USER_INVESTMENT_PRODUCT_ACTION = 'ADD_USER_INVESTMENT_PRODUCT_ACTION';
const DELETE_USER_INVESTMENT_PRODUCT_ACTION = 'DELETE_USER_INVESTMENT_PRODUCT_ACTION';
const GET_MORTGAGE_ESTIMATES_ACTION = 'GET_MORTGAGE_ESTIMATES_ACTION';
const UPDATE_SAVINGS_ACTION = 'UPDATE_SAVINGS_ACTION';


export {
  TOGGLE_MOBILE_MENU_COLLAPSE_ACTION,
  GET_CALCULATION_HOUSE_PRICE_ACTION,
  GET_USER_HOUSING_ACTION,
  GET_USER_METADATA_ACTION,
  SET_USER_LOADED_ACTION,
  SET_USER_METADATA_ACTION,
  SET_USER_CLAIMS_ACTION,
  GET_USER_TOKEN_ACTION,
  CLOSE_ACCOUNT_ACTION,
  GET_USER_INVESTMENT_PRODUCTS_ACTION,
  ADD_USER_INVESTMENT_PRODUCT_ACTION,
  DELETE_USER_INVESTMENT_PRODUCT_ACTION,
  GET_MORTGAGE_ESTIMATES_ACTION,
  UPDATE_SAVINGS_ACTION,
  GET_INVESTMENT_PRODUCTS_ACTION,
};
