import { Component, Vue, Watch } from 'vue-property-decorator';
import CountriesList from '@/app/utils/countriesList';
import { IUser } from '@/app/models';
import { mapActions, mapGetters } from 'vuex';
import { useAuth0 as VueAuth } from '@/app/auth/authWrapper';
import {
  GET_USER_CLAIMS,
  GET_USER_METADATA,
  GET_USER_METADATA_LOADED,
  GET_USER_TOKEN,
} from '@/app/store/getters/getter.types';
import { CLOSE_ACCOUNT_ACTION, SET_USER_METADATA_ACTION } from '@/app/store/actions/action.types';

@Component({
  methods: {
    ...mapActions({
      setUserMetadataAction: SET_USER_METADATA_ACTION,
      closeAccount: CLOSE_ACCOUNT_ACTION,
    }),
    ...mapGetters({
      getUserMetadata: GET_USER_METADATA,
      userMetadataLoaded: GET_USER_METADATA_LOADED,
      getUserClaims: GET_USER_CLAIMS,
      getUserToken: GET_USER_TOKEN,
    }),
  },
})
export default class SettingsPage extends Vue {
  checkPending;
  closeAccountConfirmEmailValue = '';
  countriesList = CountriesList;
  editSavingsVisible = false;
  editPasswordVisible = false;
  manageAccountVisible = false;
  canCloseAccount = false;
  passwordFormValid = false;
  closeAccountConfirmModalVisible = false;
  getUserMetadata!: () => any;
  getUserClaims!: () => any;
  getUserToken!: () => any;
  setUserMetadataAction!: (token: any) => any;
  closeAccount!: (token: any) => any;
  errors = new Set();
  user: IUser | null = null;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  profileForm = {
    name: '',
    last_name: '',
    email: '',
    day_of_birth: '',
    month_of_birth: '',
    year_of_birth: '',
    sex: '',
    telephone: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_postcode: '',
    password: '',
  };
  passwordForm = {
    password: '',
    password_repeat: '',
  };
  userLoaded = false;
  isPasswordButtonDisabled = true;
  private $auth: VueAuth;

  constructor() {
    super();
    this.user = this.getUserMetadata();
  }

  get isUserMetadataLoaded(): boolean {
    return this.getUserMetadata();
  }

  get userPicture() {
    return this.user?.picture || '';
  }

  async onEditPassword(): Promise<any> {
    const token = await this.getUserToken();
    const userId = await this.getUserClaims().sub;
    this.$refs['passwordForm']?.['validate']((valid) => {
      this.passwordFormValid = valid;
    });
    this.profileForm.password = this.passwordForm.password;
    const formData = this.getPasswordData(this.profileForm);
    this.setUserMetadataAction({ token, userId, formData }).then(() => {
      this.openPasswordMessage();
      this.editPasswordVisible = false;
    });
  }

  onCloseAccount(): void {
    const userId = this.getUserClaims().sub;
    const token = this.getUserToken();
    this.closeAccount({
      userId,
      token,
    })
      .then(() => {
        this.$auth.logout();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  closeAccountConfirmShow(): void {
    this.closeAccountConfirmModalVisible = true;
  }

  async onEditProfile(): Promise<any> {
    if (this.profileForm.day_of_birth === undefined) this.profileForm.day_of_birth = '';
    if (this.profileForm.month_of_birth === undefined) this.profileForm.month_of_birth = '';
    if (this.profileForm.year_of_birth === undefined) this.profileForm.year_of_birth = '';

    const token = await this.getUserToken();
    const userId = await this.getUserClaims().sub;
    const form = this.$refs['profileForm'] as any;
    // console.log(form);
    form.validate((valid) => {
      // console.log(valid)
      if (valid) {
        // this.profileForm.day_of_birth = '';
        // this.profileForm.month_of_birth = '';
        // this.profileForm.year_of_birth = '';
        // console.log(valid)
        const formData = this.getUserData(this.profileForm);

        this.setUserMetadataAction({ token, userId, formData }).then(() => {
          this.openSuccessMessage();
        });
      } else {
        this.errors.add('errors');
        // console.log(this.$refs['profileForm']['validate']());
      }
    });
    // console.log(form?.validate());
    // form['validate']((valid) => {
    //   console.log(valid)
    //   if (valid) {
    //     // this.profileForm.day_of_birth = '';
    //     // this.profileForm.month_of_birth = '';
    //     // this.profileForm.year_of_birth = '';
    //     console.log(valid)
    //     const formData = this.getUserData(this.profileForm);
    //
    //     this.setUserMetadataAction({ token, userId, formData }).then(() => {
    //       this.openSuccessMessage();
    //     });
    //   } else {
    //     this.errors.add('errors');
    //     console.log(this.$refs['profileForm']);
    //   }
    // });
  }

  onEditPasswordCancel(): void {
    this.editPasswordVisible = false;
  }

  onCloseAccountCancel(): void {
    this.closeAccountConfirmModalVisible = false;
  }

  onStartPasswordEdit(): void {
    this.editPasswordVisible = true;
  }

  onStopPasswordEdit(): void {
    this.editPasswordVisible = false;
  }

  handleChangeMonthOfBirth(): void {
    this.editSavingsVisible = true;
  }

  handleManageAccountVisibility(visible: boolean): void {
    this.manageAccountVisible = visible;
  }

  @Watch('isUserMetadataLoaded', {
    deep: true,
    immediate: true,
  })
  onUserLoaded(user: any) {
    if (user) {
      if (!user.data) {
        this.profileForm = this.getFormData(this.getUserMetadata());
      }
      this.userLoaded = true;
    }
  }

  @Watch('closeAccountConfirmEmailValue', {
    deep: false,
    immediate: true,
  })
  onEmailChange(email: string) {
    if (email) {
      this.canCloseAccount = this.user?.email === email;
    }
  }

  @Watch('passwordForm', {
    deep: true,
    immediate: true,
  })
  onPasswordChange() {
    this.$refs['passwordForm']?.['validate']((valid) => {
      this.passwordFormValid = valid;
    });
  }

  getUserData = (form) => {
    const { email, password, ..._form } = form;
    return {
      user_metadata: { ..._form },
    };
  };

  getPasswordData = (form) => {
    return {
      password: form.password,
    };
  };

  password = (rule, password, callback: (error?) => any): void => {
    /* eslint-disable */
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const numbers = /\d/;
    if (password.length < 8) {
      callback(new Error('Enter minimum 8 characters'));
    } else if (!format.test(password)) {
      callback(new Error('Enter at least one special character'));
    } else if (!numbers.test(password)) {
      callback(new Error('Enter at least one number'));
    } else {
      callback();
    }
  };

  passwordRepeat = (rule, password, callback: (error?) => any): void => {
    if (password !== this.passwordForm.password) {
      callback(new Error('Passwords do not match'));
    } else {
      callback();
    }
  };

  passwordRules = {
    password: [
      {
        required: true,
        trigger: 'change',
        validator: this.password,
      },
    ],
    password_repeat: [
      {
        required: true,
        trigger: 'change',
        validator: this.passwordRepeat,
      },
    ],
  };

  isEmail = (rule, email, callback: (error?) => any): void => {
    if (email === '') {
      callback();
    } else {
      if (typeof email === 'string') {
        const reg = /\S+@\S+\.\S+/;
        if (reg.test(email)) {
          callback();
        } else {
          callback(new Error('Enter an email'));
        }
      } else {
        callback(new Error('Enter an email'));
      }
    }
  };

  dayOfBirth = (rule, day, callback: (error?) => any): void => {
    if (day === '' || day === null || day === undefined) {
      const model = this.$refs['profileForm']?.['model'];
      if (!!model['year_of_birth'] || model['month_of_birth'].length > 0) {
        callback(new Error('Error'));
      } else {
        this.$refs['profileForm']?.['clearValidate']('year_of_birth');
        this.$refs['profileForm']?.['clearValidate']('month_of_birth');
        callback();
      }
    } else {
      const _day = +day;
      if (typeof _day !== null) {
        if (day.length === 0 || (_day < 32 && _day > 0)) {
          callback();
        } else {
          callback(new Error('Error'));
        }
      } else {
        callback(new Error('Error'));
      }
    }
  };

  yearOfBirth = (rule, year, callback: (error?) => any): void => {
    if (year === '' || year === null || year === undefined) {
      const model = this.$refs['profileForm']?.['model'];
      if (!!model['day_of_birth'] || model['month_of_birth'].length > 0) {
        callback(new Error('Error'));
      } else {
        this.$refs['profileForm']?.['clearValidate']('day_of_birth');
        this.$refs['profileForm']?.['clearValidate']('month_of_birth');
        callback();
      }
    } else {
      const _year = +year;
      if (typeof _year !== null) {
        if (year.length === 0 || (_year < +new Date().getFullYear() - 18 && _year > 1900)) {
          callback();
        } else {
          callback(new Error('Error'));
        }
      } else {
        callback(new Error('Error'));
      }
    }
  };

  isMonthRequired = (rule, month, callback: (error?) => any): void => {
    const model = this.$refs['profileForm']?.['model'];
    if (month === '' || month === null || month === undefined) {
      if (!!model['day_of_birth'] || !!model['year_of_birth']) {
        callback(new Error('Error'));
      } else {
        this.$refs['profileForm']?.['clearValidate']('day_of_birth');
        this.$refs['profileForm']?.['clearValidate']('year_of_birth');
        callback();
      }
    } else {
      callback();
    }
  };

  rules = {
    name: [
      {
        required: false,
        validator: null,
        trigger: 'change',
      },
    ],
    last_name: [
      {
        required: false,
        validator: null,
        trigger: 'change',
      },
    ],
    email: [
      {
        required: true,
        validator: this.isEmail,
        trigger: 'change',
      },
    ],
    day_of_birth: [
      {
        validator: this.dayOfBirth,
        trigger: 'change',
      },
    ],
    month_of_birth: [
      {
        validator: this.isMonthRequired,
        trigger: 'change',
      },
    ],
    year_of_birth: [
      {
        required: false,
        validator: this.yearOfBirth,
        trigger: 'change',
      },
    ],
    sex: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    telephone: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    address_line_1: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    address_line_2: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    address_city: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    address_postcode: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
    address_residence: [
      {
        required: false,
        validator: null,
        trigger: 'change',
        message: 'This field is required',
      },
    ],
  };

  openSuccessMessage() {
    this.$message.loading({ content: 'Updating user profile...', key: '0' });
    setTimeout(() => {
      this.$message.success({
        content: 'User profile updated!',
        key: '0',
        duration: 1,
      });
    }, 1000);
  }

  openPasswordMessage() {
    this.$message.loading({ content: 'Changing password...', key: '1' });
    setTimeout(() => {
      this.$message.success({
        content: 'Password Changed!',
        key: '1',
        duration: 1,
      });
    }, 1000);
  }

  protected getFormData(user: IUser | null): any {
    const metadata = user?.user_metadata;
    return {
      address_city: metadata?.address_city,
      address_line_1: metadata?.address_line_1,
      address_line_2: metadata?.address_line_2,
      address_postcode: metadata?.address_postcode,
      address_residence: metadata?.address_residence,
      day_of_birth: metadata?.day_of_birth,
      email: user?.email,
      last_name: metadata?.last_name,
      month_of_birth: metadata?.month_of_birth,
      name: metadata?.name,
      sex: metadata?.sex,
      telephone: metadata?.telephone,
      year_of_birth: metadata?.year_of_birth,
    };
  }
}
