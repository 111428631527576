import { IState } from '@/app/models';

const initialState: IState = {
  data: {
    investmentsProducts: [],
    calculations: {
      housePrice: {
        deposit: {
          depositBeforeFees: 0,
          depositWithFees: 0,
          stampDuty: 0,
          legalFees: 0,
          mortgageFees: 0,
          surveyorCosts: 0,
          monthlyDepositSaving: 0,
          monthlyContribution: 0,
          deposit: 0,
          homeGoal: 0,
        },
        estimatedMoveInDate: 0,
        futureHousePrice: 0,
        affordableHomePrice: 0,
        depositPercentage: 0,
        lisaAmount: 0,
        finalLoan: 0,
        savings: 0,
        yearlyEarnings: 0,
        partnerSavings: 0,
        partnerYearlyEarnings: 0,
        futureWindfall: 0,
        increaseTimeEstimatedMoveInDate: '',
        increaseTime: 0,
      },
    },
    userHousing: null,
    userHousingLoaded: false,
    userInvestmentsProducts: null,
    mortgageEstimates: null
  },
  user: null,
  app: {
    menuCollapsed: false,
    userDataLoaded: false,
  },
  userClaims: null,
  userToken: null,
};

export { initialState };
