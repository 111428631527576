import { Component, Watch } from 'vue-property-decorator';
import { LegendItem, ReviewChart, SavingProgress } from '@/app/views/components';
import {
  ICalculationHousePrice,
  IDreamArea,
  IHousing,
  IMortgageEstimates,
  IUser,
  SAVING_JOURNEY_OPTIONS,
} from '@/app/models';
import { currencyFormatter, currencyParser } from '@/app/utils/currencyFormatter';
import chartColors from './chart-colors.module.scss';
import isNumber from 'is-number';
import { mapActions, mapGetters } from 'vuex';
import { GET_CALCULATION_HOUSE_PRICE_ACTION, UPDATE_SAVINGS_ACTION } from '@/app/store/actions/action.types';
import {
  GET_CALCULATION_HOUSE_PRICE,
  GET_DREAM_AREA,
  GET_GOAL_DATASET,
  GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
  GET_MORTGAGE_ESTIMATES,
  GET_MOVE_IN_DATE,
  GET_MOVE_IN_YEARS,
  GET_USER_HOUSING,
  GET_USER_HOUSING_LOADED,
  GET_USER_METADATA,
  GET_USER_METADATA_LOADED,
} from '@/app/store/getters/getter.types';
import { useAuth0 as VueAuth } from '@/app/auth/authWrapper';
import { LoadData } from '@/app/mixins/load-data.mixin';
import { AxiosResponse } from 'axios';
import { monthDiff } from '@/app/utils/date_helpers';

const CHART_COLORS = Object.freeze([
  chartColors.depositColor,
  chartColors.stampDutyColor,
  chartColors.legalFeesColor,
  chartColors.mortgageColor,
]);

@Component({
  components: { SavingProgress, ReviewChart, LegendItem },
  methods: {
    formatCurrency: currencyFormatter,
    parseCurrency: currencyParser,
    ...mapActions({
      getCalculationHousePriceAction: GET_CALCULATION_HOUSE_PRICE_ACTION,
      updateSavingsAction: UPDATE_SAVINGS_ACTION,
    }),
    ...mapGetters({
      getCalculationHousePrice: GET_CALCULATION_HOUSE_PRICE,
      getGoalDataSet: GET_GOAL_DATASET,
      getUserHousing: GET_USER_HOUSING,
      getUserMetadata: GET_USER_METADATA,
      getUserHousingLoaded: GET_USER_HOUSING_LOADED,
      getDreamArea: GET_DREAM_AREA,
      getMoveInDate: GET_MOVE_IN_DATE,
      getMoveInYears: GET_MOVE_IN_YEARS,
      getMortgageEstimates: GET_MORTGAGE_ESTIMATES,
      getIncreaseTimeEstimatedMoveInDate: GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
      getUserLoaded: GET_USER_METADATA_LOADED,
    }),
  },
})
export default class DashboardPage extends LoadData {
  editSavingsVisible = false;
  editSavingsWithPartnerVisible = false;

  savingsWithPartnerRadio = 1;
  monthlyContributionWithPartnerRadio = 3;

  editSavingsForm = {
    savings: 0,
    monthlyContribution: 0,
  };

  editSavingsWithPartnerForm = {
    savings: 0,
    monthlyContribution: 0,
    partnerSavings: 0,
    partnerMonthlyContribution: 0,
  };
  private userLoaded = false;
  private housingLoaded = false;
  private isSavingsValid = true;
  private isMonthlyContributionValid = true;

  private getCalculationHousePrice!: () => ICalculationHousePrice;
  private getGoalDataSet!: () => number[];
  private getUserHousing!: () => IHousing;
  private getUserMetadata!: () => IUser;
  private getUserHousingLoaded!: () => boolean;
  private getDreamArea!: () => IDreamArea;
  private getMoveInDate!: () => string;
  private getUserLoaded!: () => boolean;
  private getMortgageEstimates!: () => IMortgageEstimates;
  private getIncreaseTimeEstimatedMoveInDate!: () => string;
  // private getEstimatedMoveInDate!: () => string;
  private updateSavingsAction!: (payload) => any;
  private $auth: VueAuth;

  // get increasedDate(): string {
  //   const date = new Date(this.getIncreaseTimeEstimatedMoveInDate());
  //   const now = new Date();
  //   const diff = monthDiff(now, date);
  //   return diff < 13 ? diff + ' months left' : Math.floor(diff / 12) + ' years left';
  // }

  get increasedDateFull(): string {
    const date = new Date(this.getIncreaseTimeEstimatedMoveInDate());
    const now = new Date();
    const diff = monthDiff(now, date);
    const yearDiff = Math.floor(diff / 12) + ' years ';
    const monthsDiff = (diff % 12) + ' months left';
    return yearDiff + monthsDiff;
  }

  get hasPartner(): boolean {
    return this.userHousing.hasPartner;
  }

  get dashboardReady(): boolean {
    return this.getUserLoaded() && this.getUserHousingLoaded();
  }

  get dreamArea(): IDreamArea {
    return this.getDreamArea();
  }

  get propertyType(): string {
    return (
      this.dreamArea.bedrooms +
      ' bed ' +
      (this.dreamArea.propertyType ? this.dreamArea.propertyType?.replace('_', ' ') : '')
    );
  }

  get moveInDate(): string {
    return this.getMoveInDate();
  }

  get mortgageEstimates(): IMortgageEstimates {
    return this.getMortgageEstimates();
  }

  get monthlyRepaymentLower(): number {
    return this.mortgageEstimates.mpl;
  }

  get monthlyRepaymentUpper(): number {
    return this.mortgageEstimates.mpu;
  }

  get estimateInterestLower(): string {
    return (this.mortgageEstimates.mrl * 100).toFixed(2).toString();
  }

  get estimateInterestUpper(): string {
    return (this.mortgageEstimates.mru * 100).toFixed(2).toString();
  }

  // get journeyOption() {
  //   const option = SAVING_JOURNEY_OPTIONS.get(this.getDreamArea()?.savingJourneyOption) as string[];
  //   return this.hasPartner ? option[1] : option[0];
  // }

  get title() {
    return 'Welcome '.concat(<string>this.user.user_metadata?.name).concat('!');
  }

  get labels(): string[] {
    return ['Deposit*', 'Stamp Duty', 'Legal Fees', 'Mortgage & Surveyor Fees'];
  }

  get tooltipKeys() {
    return [
      'The amount you can expect to put down for your home.',
      'A tax you may be required to pay on the home you buy. These tax rates differ by region, property purchase price, and whether or not you are a first-time buyer.',
      'The average amount you could expect to pay a solicitor or licensed conveyor to carry out legal work when buying your home.',
      'An average estimate of the fees associated with approving your mortgage including booking, arrangement and valuation, combined with fees to carry out a property survey.',
    ];
  }

  get legendItems(): Array<Record<string, unknown>> {
    const dataset = this.getGoalDataSet() ? this.getGoalDataSet() : [0];
    return dataset.map((value: number, index: number) => {
      return {
        value: currencyFormatter(value, 100),
        concern: this.labels[index],
        color: CHART_COLORS[index],
        tooltipKey: this.tooltipKeys[index],
      };
    });
  }

  get calculationHousePrice(): ICalculationHousePrice {
    return this.getCalculationHousePrice();
  }

  get userHousing(): IHousing {
    return this.getUserHousing();
  }

  get affordableHomePrice() {
    return currencyFormatter(this.calculationHousePrice.affordableHomePrice, 100);
  }

  get user(): IUser {
    return {
      ...this.getUserMetadata(),
    };
  }

  // get isUserLoaded(): boolean {
  //   return this.userLoaded;
  // }

  get showCompleteAccountAlert() {
    return !localStorage.getItem('completeAccount');
  }

  get savings(): number {
    return this.userHousing?.savings || 0;
  }

  get partnerSavings(): number {
    return this.userHousing?.partnerSavings || 0;
  }

  get monthlyContribution(): number {
    return this.userHousing?.monthlyContribution || 0;
  }

  get partnerMonthlyContribution(): number {
    return this.userHousing?.partnerMonthlyContribution || 0;
  }

  get homeStatus(): string {
    let journey;
    if (this.userHousing.thinkingFirstTimeBuyer) journey = 1;
    if (this.userHousing.firstTimeBuyer) journey = 2;
    if (this.userHousing.switchingHome) journey = 3;
    if (this.userHousing.additionalHouse) journey = 4;
    const journeyOption = SAVING_JOURNEY_OPTIONS.get(journey) as string[];
    if (!Array.isArray(journeyOption)) return 'Unknown';
    return !this.hasPartner ? journeyOption[0] : journeyOption[1];
  }

  get checkSavings(): boolean {
    return !this.isSavingsValid;
  }

  get checkMonthlyContribution(): boolean {
    return !this.isMonthlyContributionValid;
  }

  // mounted(): void {
  // this.loadingData = !!this.getUserHousing();
    // console.log(this);
  // console.log(JSON.stringify(this.userHousing, undefined, 2));
  // }

  // refreshUserProducts() {
  //   this.$auth.getTokenSilently().then((token) => {
  //     const moveInYears = this.moveInYears;
  //     this.getUserProductsAction({ token, moveInYears });
  //     this.getUserHousingAction(token).then((userHousing: IHousing) => {
  //       userHousing['firstTimeBuyerState'] = this.getBuyerState(userHousing);
  //       this.getHousingCalculation(userHousing).then(() => {});
  //     });
  //   });
  // }

  // getHousing() {
  //   return this.getUserHousing();
  // }

  onEditSavings(): void {
    this.editSavingsVisible = false;
  }

  onEditSavingsWithPartner(): void {
    this.editSavingsWithPartnerVisible = false;
  }

  onStartEditSavings(): void {
    // this.editSavingsVisible = true;
    this.$auth.getTokenSilently().then((token) => {
      // console.log(token);
      // console.log('started');
      this.$refs['editSavingsForm']?.['validate']((valid) => {
        if (valid) {
          const payload = {
            token: token,
            data: {
              monthlyContribution: +this.editSavingsForm.monthlyContribution,
              savings: +this.editSavingsForm.savings,
              partnerMonthlyContribution: +this.userHousing.partnerMonthlyContribution,
              partnerSavings: +this.userHousing.partnerSavings,
            },
            id: this.userHousing.id,
          };
          this.housingLoaded = false;
          this.editSavingsVisible = false;
          this.updateSavingsAction(payload).then((data: AxiosResponse) => {
            if (data.status === 200) {
              this.getUserHousingAction(token).then((userHousing: IHousing) => {
                // console.log(userHousing);
                userHousing['firstTimeBuyerState'] = this.getBuyerState(userHousing);
                this.getHousingCalculation({ userHousing, token }).then((calculation) => {
                  this.housingLoaded = true;
                  const moveInYears = this.moveInYears;
                  this.getUserProductsAction({ token, moveInYears });
                  this.getMortgageEstimatesAction({
                    token: token,
                    payload: {
                      moveInMonths: calculation.increaseTime,
                      finalLoan: this.finalLoan,
                    },
                  });
                });
              });
            }
          });
        }
      });
    });
  }

  onStartEditSavingsWithPartner(): void {
    // this.editSavingsVisible = true;
    this.$auth.getTokenSilently().then((token) => {
      // console.log(token);
      // console.log('started');
      this.$refs['editSavingsWithPartnerForm']?.['validate']((valid) => {
        if (valid) {
          const payload = {
            token: token,
            data: {
              monthlyContribution: +this.editSavingsWithPartnerForm.monthlyContribution,
              savings: +this.editSavingsWithPartnerForm.savings,
              partnerMonthlyContribution: +this.editSavingsWithPartnerForm.partnerMonthlyContribution,
              partnerSavings: +this.editSavingsWithPartnerForm.partnerSavings,
            },
            id: this.userHousing.id,
          };
          this.housingLoaded = false;
          this.editSavingsWithPartnerVisible = false;
          this.updateSavingsAction(payload).then((data: AxiosResponse) => {
            if (data.status === 200) {
              this.getUserHousingAction(token).then((userHousing: IHousing | any) => {
                // console.log(userHousing);
                userHousing['firstTimeBuyerState'] = this.getBuyerState(userHousing);
                const payload = { userHousing, token };
                this.getHousingCalculation(payload).then((calculation) => {
                  this.housingLoaded = true;
                  const moveInYears = this.moveInYears;
                  this.getUserProductsAction({ token, moveInYears });
                });
              });
            }
          });
        }
      });
    });
  }

  openEditSavingsModal(): void {
    this.setForms();
    this.hasPartner ? (this.editSavingsWithPartnerVisible = true) : (this.editSavingsVisible = true);
  }

  handleCancel(): void {
    this.resetForms();
  }

  @Watch('getUserLoaded', {
    deep: true,
    immediate: true,
  })
  laded() {
    setTimeout(() => {
      this.userLoaded = this.getUserLoaded() as boolean;
    }, 10);
  }

  @Watch('getUserHousingLoaded', {
    deep: true,
    immediate: true,
  })
  userHousingLoaded(loaded) {
    let i = 0;
    setTimeout(() => {
      this.userLoaded = loaded();
      if (!this.userLoaded || i < 5) {
        i++;
        this.getUserHousingLoaded();
      } else {
        this.setForms();
      }
    }, 150);
  }

  number = (rule, value, callback: (error?) => any): void => {
    if (value === '') {
      callback(new Error('This field is required'));
    }
    if (isNumber(value)) {
      callback();
    } else {
      callback(new Error('Enter a number'));
    }
  };

  rules = {
    savings: [
      {
        required: true,
        validator: this.number,
        trigger: 'change',
      },
    ],
    partnerSavings: [
      {
        required: true,
        validator: this.number,
        trigger: 'change',
      },
    ],
    monthlyContribution: [
      {
        required: true,
        validator: this.number,
        trigger: 'change',
      },
    ],
    partnerMonthlyContribution: [
      {
        required: true,
        validator: this.number,
        trigger: 'change',
      },
    ],
  };

  onCloseAlert() {
    localStorage.setItem('completeAccount', '1');
  }

  getSavings() {
    return this.savings;
  }

  onChangeSavings() {
    this.$refs['editSavingsWithPartnerForm']?.['validateField'](['savings'], (error) => {
      this.isSavingsValid = !error;
    });
  }

  onChangeSavingsWithPartner() {
    this.$refs['editSavingsWithPartnerForm']?.['validateField'](['partnerSavings'], (error) => {
      this.isSavingsValid = !error;
    });
  }

  onChangeMonthlyContribution() {
    this.$refs['editSavingsWithPartnerForm']?.['validateField'](['monthlyContribution'], (error) => {
      this.isMonthlyContributionValid = !error;
    });
  }

  onChangeMonthlyContributionWithPartner() {
    this.$refs['editSavingsWithPartnerForm']?.['validateField'](['partnerMonthlyContribution'], (error) => {
      this.isMonthlyContributionValid = !error;
    });
  }

  resetForms() {
    this.$refs['editSavingsWithPartnerForm']?.['resetFields']();
    this.$refs['editSavingsForm']?.['resetFields']();
    this.editSavingsVisible = false;
    this.editSavingsWithPartnerVisible = false;
    this.isMonthlyContributionValid = true;
    this.isSavingsValid = true;
  }

  private setForms() {
    this.editSavingsForm = {
      savings: this.userHousing?.savings,
      monthlyContribution: this.userHousing?.monthlyContribution,
    };
    this.editSavingsWithPartnerForm = {
      savings: this.userHousing?.savings,
      partnerSavings: this.userHousing?.partnerSavings,
      monthlyContribution: this.userHousing?.monthlyContribution,
      partnerMonthlyContribution: this.userHousing?.partnerMonthlyContribution,
    };
  }
}
