import { RouteConfig } from 'vue-router';
import PAGES from '@/app/config/pages';
import {
  DashboardPage,
  HomePlaygroundPage,
  SavingsSimulatorPage,
  SettingsPage,
} from '@/app/views/pages';
import { SidebarLayout } from '@/app/views/layouts';
import authGuard from '../auth';

export const mainRoutes: Array<RouteConfig> = [
  {
    path: PAGES.DASHBOARD_PAGE_PATH,
    name: PAGES.DASHBOARD_PAGE_NAME,
    component: DashboardPage,
    beforeEnter: authGuard.authGuard,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: true,
        icon: 'home',
        link: true,
      },
    },
  },
  {
    path: PAGES.HOME_PLAYGROUND_PAGE_PATH,
    name: PAGES.HOME_PLAYGROUND_PAGE_NAME,
    component: HomePlaygroundPage,
    beforeEnter: authGuard.authGuard,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: true,
        icon: 'container',
        link: false,
      },
    },
  },
  {
    path: PAGES.SAVINGS_SIMULATOR_PAGE_PATH,
    name: PAGES.SAVINGS_SIMULATOR_PAGE_NAME,
    component: SavingsSimulatorPage,
    beforeEnter: authGuard.authGuard,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: true,
        icon: 'pound',
        iconActive: 'pound-circle',
        link: true,
      },
    },
  },
  {
    path: PAGES.SETTINGS_PAGE_PATH,
    name: PAGES.SETTINGS_PAGE_NAME,
    component: SettingsPage,
    beforeEnter: authGuard.authGuard,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: false,
        link: false,
      },
    },
  },
  {
    path: '/signedout',
    name: 'signedout',
    component: HomePlaygroundPage,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: false,
        link: false,
      },
    },
  },
  {
    path: PAGES.SETTINGS_PAGE_PATH,
    name: PAGES.SETTINGS_PAGE_NAME,
    component: SettingsPage,
    meta: {
      layout: SidebarLayout,
      menu: {
        sidebar: true,
        icon: 'setting',
        link: false,
      },
    },
    children: [
      {
        path: PAGES.SETTINGS_PAGE_PATH + PAGES.PROFILE_PAGE_PATH,
        name: PAGES.PROFILE_PAGE_NAME,
        component: SettingsPage,
        beforeEnter: authGuard.authGuard,
        meta: {
          layout: SidebarLayout,
          menu: {
            sidebar: true,
            link: true,
          },
        },
      },
      /*      {
        path: PAGES.SETTINGS_PAGE_PATH + PAGES.NOTIFICATIONS_PAGE_PATH,
        name: PAGES.NOTIFICATIONS_PAGE_NAME,
        component: SettingsPage,
        meta: {
          layout: SidebarLayout,
          menu: {
            sidebar: true,
            link: true,
          },
        },
      },*/
    ],
  },
];
