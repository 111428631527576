const MENU_MOBILE_COLLAPSED = 'MENU_MOBILE_COLLAPSED';
const GET_CALCULATION_HOUSE_PRICE = 'GET_CALCULATION_HOUSE_PRICE';
const GET_ACTUAL_DEPOSIT = 'GET_ACTUAL_DEPOSIT';
const GET_GOAL_DATASET = 'GET_GOAL_DATASET';
const GET_USER_HOUSING = 'GET_USER_HOUSING';
const GET_USER_HOUSING_LOADED = 'GET_USER_HOUSING_LOADED';
const GET_USER_METADATA = 'GET_USER_METADATA';
const GET_USER_METADATA_LOADED = 'GET_USER_METADATA_LOADED';
const GET_USER_CLAIMS = 'GET_USER_CLAIMS';
const GET_USER_TOKEN = 'GET_USER_TOKEN';
const GET_USER_INVESTMENT_PRODUCTS = 'GET_USER_INVESTMENT_PRODUCTS';
const GET_INVESTMENT_PRODUCTS = 'GET_INVESTMENT_PRODUCTS';
const GET_MOVE_IN_YEARS = 'GET_MOVE_IN_YEARS';
const GET_MOVE_IN_DATE = 'GET_MOVE_IN_DATE';
const GET_DREAM_AREA = 'GET_DREAM_AREA';
const GET_MORTGAGE_ESTIMATES = 'GET_MORTGAGE_ESTIMATES';
const GET_FINAL_LOAN = 'GET_FINAL_LOAN';
const GET_RUNNING_TOTALS = 'GET_RUNNING_TOTALS';
const GET_TIME_SAVED = 'GET_TIME_SAVED';
const GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE = 'GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE';
const GET_ESTIMATED_MOVE_IN_DATE = 'GET_ESTIMATED_MOVE_IN_DATE';

export {
  GET_ACTUAL_DEPOSIT,
  GET_GOAL_DATASET,
  GET_CALCULATION_HOUSE_PRICE,
  MENU_MOBILE_COLLAPSED,
  GET_USER_HOUSING,
  GET_USER_HOUSING_LOADED,
  GET_USER_METADATA,
  GET_USER_METADATA_LOADED,
  GET_USER_CLAIMS,
  GET_USER_TOKEN,
  GET_USER_INVESTMENT_PRODUCTS,
  GET_INVESTMENT_PRODUCTS,
  GET_MOVE_IN_YEARS,
  GET_MOVE_IN_DATE,
  GET_DREAM_AREA,
  GET_MORTGAGE_ESTIMATES,
  GET_FINAL_LOAN,
  GET_RUNNING_TOTALS,
  GET_TIME_SAVED,
  GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
  GET_ESTIMATED_MOVE_IN_DATE,
};
