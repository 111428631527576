import Vue from 'vue';
import VueRouter from 'vue-router';
import { mainRoutes } from '@/app/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: mainRoutes,
});

/*router.afterEach((to: Route, from: Route) => {
  console.log(to);
  console.log(from);
});*/

export default router;
