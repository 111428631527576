import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'legend-item',
  components: {},
})
export default class LegendItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: Record<string, unknown>;
}
