const DASHBOARD_PAGE_NAME = 'Dashboard';
const DASHBOARD_PAGE_PATH = '/';
const HOME_PLAYGROUND_PAGE_NAME = 'Home playground';
const HOME_PLAYGROUND_PAGE_PATH = '/home-playground';
const SAVINGS_SIMULATOR_PAGE_NAME = 'Savings Simulator';
const SAVINGS_SIMULATOR_PAGE_PATH = '/savings-simulator';
const SETTINGS_PAGE_NAME = 'Settings';
const SETTINGS_PAGE_PATH = '/settings';
const PROFILE_PAGE_NAME = 'My Profile';
const PROFILE_PAGE_PATH = '/profile';
const NOTIFICATIONS_PAGE_NAME = 'Notifications';
const NOTIFICATIONS_PAGE_PATH = '/notifications';

export default {
  DASHBOARD_PAGE_NAME,
  DASHBOARD_PAGE_PATH,
  HOME_PLAYGROUND_PAGE_NAME,
  HOME_PLAYGROUND_PAGE_PATH,
  SAVINGS_SIMULATOR_PAGE_NAME,
  SAVINGS_SIMULATOR_PAGE_PATH,
  SETTINGS_PAGE_NAME,
  SETTINGS_PAGE_PATH,
  PROFILE_PAGE_NAME,
  PROFILE_PAGE_PATH,
  NOTIFICATIONS_PAGE_NAME,
  NOTIFICATIONS_PAGE_PATH,
};
