import { ICalculationHousePrice, IHousing, IState, IUser } from '@/app/models';
import {
  GET_CALCULATION_HOUSE_PRICE_MUTATION,
  GET_USER_HOUSING_MUTATION,
  TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION,
  GET_USER_METADATA_MUTATION,
  SET_USER_LOADED_MUTATION,
  SET_USER_CLAIMS_MUTATION,
  GET_USER_TOKEN_MUTATION,
  SET_USER_INVESTMENTS_PRODUCTS_MUTATION,
  GET_INVESTMENT_PRODUCTS_MUTATION,
  SET_MORTGAGE_ESTIMATES_MUTATION,
  GET_USER_HOUSING_LOADED_MUTATION,
} from '@/app/store/mutations/mutation.types';

export default {
  [TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION](state: IState, payload: boolean): void {
    state.app.menuCollapsed = payload;
  },

  [GET_CALCULATION_HOUSE_PRICE_MUTATION](state: IState, payload: ICalculationHousePrice): void {
    state.data.calculations.housePrice = payload;
  },

  [GET_USER_HOUSING_MUTATION](state: IState, payload: IHousing): void {
    state.data.userHousing = payload;
    state.data.userHousingLoaded = true;
  },
  [GET_USER_HOUSING_LOADED_MUTATION](state: IState, payload: boolean) {
    state.data.userHousingLoaded = payload;
  },

  [GET_USER_METADATA_MUTATION](state: IState, payload: IUser): void {
    state.user = payload;
  },

  [SET_USER_LOADED_MUTATION](state: IState, payload: boolean): void {
    state.app.userDataLoaded = payload;
  },

  [SET_USER_CLAIMS_MUTATION](state: IState, payload: boolean): void {
    state.userClaims = payload;
  },

  [GET_USER_TOKEN_MUTATION](state: IState, payload: string): void {
    state.userToken = payload;
  },

  [SET_USER_INVESTMENTS_PRODUCTS_MUTATION](state: IState, payload: any): void {
    state.data.userInvestmentsProducts = payload;
  },

  [SET_MORTGAGE_ESTIMATES_MUTATION](state: IState, payload: any): void {
    state.data.mortgageEstimates = payload;
  },

  [GET_INVESTMENT_PRODUCTS_MUTATION](state: IState, payload: any): void {
    state.data.investmentsProducts = payload;
  },
};
