import { render, staticRenderFns } from "./SidebarLogo.html?vue&type=template&id=37404aba&scoped=true&external"
var script = {}
import style0 from "./SidebarLogo.scss?vue&type=style&index=0&id=37404aba&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37404aba",
  null
  
)

export default component.exports