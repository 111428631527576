import { Component, Prop, Vue } from 'vue-property-decorator';
import { currencyFormatter } from '@/app/utils/currencyFormatter';
import { ICalculationHousePrice, IHousing } from '@/app/models';
import { mapGetters } from 'vuex';
import { GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE } from '@/app/store/getters/getter.types';
import { calculatePercentage } from '@/app/utils/percentage';
import { monthDiff } from '@/app/utils/date_helpers';

@Component({
  components: {},
  methods: {
    formatCurrency: currencyFormatter,
    ...mapGetters({
      getIncreaseTimeEstimatedMoveInDate: GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
    }),
  },
})
export default class SavingProgress extends Vue {
  @Prop()
  calculationHousePrice!: ICalculationHousePrice;

  @Prop()
  userHousing!: IHousing;

  @Prop()
  size: string | undefined;
  collapsed = false;
  collapsePanelName = 'details';
  private getIncreaseTimeEstimatedMoveInDate!: () => string;

  get hasPartner(): boolean {
    return this.userHousing.hasPartner;
  }

  get progressSize(): string {
    return this.size || '';
  }

  get increasedDate(): string {
    const date = new Date(this.getIncreaseTimeEstimatedMoveInDate());
    const now = new Date();
    const diff = monthDiff(now, date);
    const months = diff % 12;
    const years = Math.floor(diff / 12);
    if (months > 0) {
      return years + ' years ' + months + ' months left';
    }
    return years + ' years left';
  }

  get depositPercentage(): number {
    const percentage = ((this.actualSavings / this.futureHousePrice) * 100).toFixed(0);
    return +percentage < 100 ? +percentage : 100;
  }

  get totalPercentage(): number {
    return this.calculationHousePrice.depositPercentage;
  }

  get progressSavingsPercentage(): number {
    const goal = this.futureHousePrice;
    const savings = this.userHousing.savings;
    return +calculatePercentage(savings, goal);
  }

  get partnerProgressSavingsPercentage(): number {
    const goal = this.futureHousePrice;
    const savings = this.userHousing.partnerSavings;
    return +calculatePercentage(savings, goal);
  }

  get progressAdditionalMoneyPercentage(): number {
    const goal = this.futureHousePrice;
    const savings = this.userHousing.savings;
    const additionalMoney = this.userHousing.expectedMoney;
    return +calculatePercentage(savings + additionalMoney, goal);
  }

  get partnerProgressAdditionalMoneyPercentage(): number {
    const goal = this.futureHousePrice;
    const savings = this.userHousing.partnerSavings;
    const additionalMoney = this.userHousing.partnerExpectedMoney;
    return +calculatePercentage(savings + additionalMoney, goal);
  }

  get actualSavings(): number {
    return this.hasPartner
      ? this.userHousing.savings +
          this.userHousing.partnerSavings +
          this.userHousing.expectedMoney +
          this.userHousing.partnerExpectedMoney
      : this.userHousing.savings + this.userHousing.expectedMoney;
  }

  get futureHousePrice(): number {
    return this.calculationHousePrice?.deposit.homeGoal;
  }

  get combinedMonthlySavings(): any {
    return this.userHousing?.monthlyContribution + this.userHousing?.partnerMonthlyContribution;
  }

  get collapsePanelHeader(): string {
    return this.collapsed ? 'Hide details' : 'Show details';
  }

  get savings(): number {
    return this.userHousing?.savings + this.expectedMoney;
  }

  get expectedMoney(): number {
    return this.userHousing?.expectedMoney;
  }

  get partnerSavings(): number {
    return this.userHousing?.partnerSavings;
  }

  get partnerTotalSavings(): number {
    return this.userHousing?.partnerSavings + this.userHousing?.partnerExpectedMoney;
  }

  get partnerMonthlyContribution(): number {
    return this.userHousing?.partnerMonthlyContribution;
  }

  onCollapseChange(panels: string[]): void {
    this.collapsed = panels.includes(this.collapsePanelName);
  }
}
