import Vue from 'vue';
import Component from 'vue-class-component';

import { mapActions, mapGetters } from 'vuex';
import {
  GET_CALCULATION_HOUSE_PRICE_ACTION,
  GET_INVESTMENT_PRODUCTS_ACTION,
  GET_MORTGAGE_ESTIMATES_ACTION,
  GET_USER_HOUSING_ACTION,
  GET_USER_INVESTMENT_PRODUCTS_ACTION,
  GET_USER_METADATA_ACTION,
  GET_USER_TOKEN_ACTION,
  SET_USER_CLAIMS_ACTION,
  SET_USER_LOADED_ACTION,
} from '@/app/store/actions/action.types.ts';
import { GET_FINAL_LOAN, GET_MOVE_IN_YEARS, MENU_MOBILE_COLLAPSED } from '@/app/store/getters/getter.types.ts';
import { getInstance } from '@/app/auth/authWrapper';
import { IHousing } from '@/app/models';

@Component({
  methods: {
    ...mapActions({
      getUserHousingAction: GET_USER_HOUSING_ACTION,
      getUserMetadataAction: GET_USER_METADATA_ACTION,
      getHousingCalculation: GET_CALCULATION_HOUSE_PRICE_ACTION,
      setUserLoaded: SET_USER_LOADED_ACTION,
      setUserClaimsAction: SET_USER_CLAIMS_ACTION,
      getUserTokenAction: GET_USER_TOKEN_ACTION,
      getUserProductsAction: GET_USER_INVESTMENT_PRODUCTS_ACTION,
      getMortgageEstimatesAction: GET_MORTGAGE_ESTIMATES_ACTION,
      getProductsAction: GET_INVESTMENT_PRODUCTS_ACTION,
    }),
    ...mapGetters({
      menuCollapsed: MENU_MOBILE_COLLAPSED,
      getMoveInYears: GET_MOVE_IN_YEARS,
      getFinalLoan: GET_FINAL_LOAN,
    }),
  },
})
export class LoadData extends Vue {
  getUserHousingAction!: (token: string) => any;
  getUserMetadataAction!: (token: any) => any;
  getUserTokenAction!: () => any;
  getHousingCalculation!: (payload) => any;
  getMoveInYears!: () => number;
  getFinalLoan!: () => number;
  setUserLoaded!: (loaded: boolean) => boolean;
  getUserProductsAction!: (token: any) => any;
  setUserClaimsAction!: (claims: any) => any;
  getMortgageEstimatesAction!: (payload: any) => any;
  getProductsAction!: () => any;
  dashboardLoaded = false;

  private userClaims: any;

  get moveInYears() {
    return this.getMoveInYears();
  }

  get finalLoan() {
    return this.getFinalLoan();
  }

  init(fn) {
    const instance = getInstance();
    instance.$watch('loading', (loading) => {
      if (loading === false) {
        fn(instance);
      }
    });
  }

  getBuyerState(housing: IHousing): string {
    const thinkingHome = 'thinking';
    const additionalHome = 'additionalHouse';
    const switchingHome = 'switchingHouse';
    const savingForFirstHome = 'firstHome';
    const option = JSON.parse(housing.dreamAreaJson as string).savingJourneyOption as number;
    switch (option) {
      case 0:
        return 'not_saved';
      case 1:
        return thinkingHome;
      case 2:
        return savingForFirstHome;
      case 3:
        return switchingHome;
      case 4:
        return additionalHome;
      default:
        return '';
    }
  }

  async initCallback(instance) {
    await instance.getTokenSilently().then((token) => {
      this.getUserHousingAction(token).then((userHousing: IHousing) => {
        userHousing['firstTimeBuyerState'] = this.getBuyerState(userHousing);
        this.getHousingCalculation({ userHousing, token }).then((calculation) => {
          this.dashboardLoaded = true;
          const moveInYears = this.moveInYears;
          this.getUserProductsAction({ token, moveInYears });
          this.getMortgageEstimatesAction({
            token: token,
            payload: {
              moveInMonths: calculation.increaseTime,
              finalLoan: this.finalLoan,
            },
          }).then((data) => {
            instance.getIdTokenClaims().then((claims) => {
              this.getUserTokenAction().then(() => {
                this.userClaims = claims;
                const token = claims.__raw;
                const userId = claims.sub;
                this.setUserClaimsAction(claims);
                this.getUserMetadataAction({ token, userId }).then(() => {
                  setTimeout(() => {
                    this.setUserLoaded(true);
                  }, 200);
                });
              });
            });
            return data;
          });
          this.getProductsAction();
        });
      });
    });
  }
}
