import { Component } from 'vue-property-decorator';
import {
  ICalculationHousePrice,
  IHousing,
  InvestmentProductType,
  IRunningTotals,
  ITimeSaved,
  IUserInvestmentsProduct,
} from '@/app/models';
import isNumber from 'is-number';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_CALCULATION_HOUSE_PRICE,
  GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
  GET_INVESTMENT_PRODUCTS,
  GET_MOVE_IN_DATE,
  GET_MOVE_IN_YEARS,
  GET_RUNNING_TOTALS,
  GET_TIME_SAVED,
  GET_USER_CLAIMS,
  GET_USER_HOUSING,
  GET_USER_HOUSING_LOADED,
  GET_USER_INVESTMENT_PRODUCTS,
  GET_USER_METADATA,
  GET_USER_METADATA_LOADED,
} from '@/app/store/getters/getter.types';
import {
  ADD_USER_INVESTMENT_PRODUCT_ACTION,
  DELETE_USER_INVESTMENT_PRODUCT_ACTION,
  GET_CALCULATION_HOUSE_PRICE_ACTION,
  GET_USER_HOUSING_ACTION,
  GET_USER_INVESTMENT_PRODUCTS_ACTION,
} from '@/app/store/actions/action.types';
import { useAuth0 as VueAuth } from '@/app/auth/authWrapper';
import { currencyFormatter, currencyParser } from '@/app/utils/currencyFormatter';
import { dateMonths, monthDiff } from '@/app/utils/date_helpers';
import { LoadData } from '@/app/mixins/load-data.mixin';

@Component({
  methods: {
    ...mapGetters({
      getUserClaims: GET_USER_CLAIMS,
      getUserProducts: GET_USER_INVESTMENT_PRODUCTS,
      getUserHousing: GET_USER_HOUSING,
      getInvestmentProducts: GET_INVESTMENT_PRODUCTS,
      getMoveInYears: GET_MOVE_IN_YEARS,
      getMoveInDate: GET_MOVE_IN_DATE,
      getRunningTotals: GET_RUNNING_TOTALS,
      getTimeSaved: GET_TIME_SAVED,
      getCalculationHousePrice: GET_CALCULATION_HOUSE_PRICE,
      getIncreaseTimeEstimatedMoveInDate: GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
      getUserHousingLoaded: GET_USER_HOUSING_LOADED,
      getUserLoaded: GET_USER_METADATA_LOADED,
    }),
    ...mapActions({
      addUserInvestmentProduct: ADD_USER_INVESTMENT_PRODUCT_ACTION,
      deleteUserProduct: DELETE_USER_INVESTMENT_PRODUCT_ACTION,
      getUserProductsAction: GET_USER_INVESTMENT_PRODUCTS_ACTION,
      getUserHousingAction: GET_USER_HOUSING_ACTION,
      getHousingCalculation: GET_CALCULATION_HOUSE_PRICE_ACTION,
    }),
    formatCurrency: currencyFormatter,
    parseCurrency: currencyParser,
  },
})
export default class SavingsSimulatorPage extends LoadData {
  // getUserClaims!: () => any;
  getUserProducts!: () => any;
  getMoveInYears!: () => number;
  // getMoveInDate!: () => string;
  getInvestmentProducts!: () => string;
  getCalculationHousePrice!: () => ICalculationHousePrice;
  addUserInvestmentProduct!: (user) => any;
  deleteUserProduct!: (user) => any;
  getUserProductsAction!: (token: any) => any;
  getUserHousingAction!: (token: string) => any;
  getHousingCalculation!: (payload) => any;
  investmentProductType = InvestmentProductType;
  expanded = {
    cashLifetime: false,
    cashLifetimeForm: false,

    fixedRate: false,
    fixedRateForm: false,

    easyAccess: false,
    easyAccessForm: false,

    stockShares: false,
    stockSharesForm: false,

    cautiousPortfolio: false,
    cautiousPortfolioForm: false,

    steadyPortfolio: false,
    boldPortfolio: false,
    cautiousPortfolioShares: false,
    steadyPortfolioShares: false,
    boldPortfolioShares: false,
  };
  defaultForm = {
    initialSum: '',
    monthlyAmount: '',
  };
  cashLifetimeForm = { ...this.defaultForm };
  cashLifetimeError: string | null = null;
  easyAccessForm = { ...this.defaultForm };
  easyAccessError: string | null = null;
  cautiousPortfolioForm = { ...this.defaultForm };
  cautiousPortfolioError: string | null = null;
  steadyPortfolioForm = { ...this.defaultForm };
  steadyPortfolioError: string | null = null;
  boldPortfolioForm = { ...this.defaultForm };
  boldPortfolioError: string | null = null;
  cautiousPortfolioSharesForm = { ...this.defaultForm };
  cautiousPortfolioSharesError: string | null = null;
  steadyPortfolioSharesForm = { ...this.defaultForm };
  steadyPortfolioSharesError: string | null = null;
  boldPortfolioSharesForm = { ...this.defaultForm };
  boldPortfolioSharesError: string | null = null;
  fixedRateForm = {
    initialSum: '',
    fixedTermYears: '1',
  };
  fixedRateError: string | null = null;
  private getIncreaseTimeEstimatedMoveInDate!: () => string;
  private getUserHousing!: () => IHousing;
  private getRunningTotals!: () => IRunningTotals;
  private getTimeSaved!: () => ITimeSaved;
  private getUserLoaded!: () => boolean;
  private getUserHousingLoaded!: () => boolean;
  private $auth: VueAuth;

  get dashboardReady(): boolean {
    return this.getUserLoaded() && this.getUserHousingLoaded();
  }

  get formLayout() {
    // @ts-ignore
    return this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md' ? 'horizontal' : 'inline';
  }

  get hasPartner(): boolean {
    return this.userHousing?.hasPartner;
  }

  get futureHousePrice(): number {
    return this.calculationHousePrice?.deposit.homeGoal;
  }

  get calculationHousePrice(): ICalculationHousePrice {
    return this.getCalculationHousePrice();
  }

  get depositPercentage(): number {
    const percentage = ((this.actualSavings / this.futureHousePrice) * 100).toFixed(0);
    return +percentage < 100 ? +percentage : 100;
  }

  get actualSavings(): number {
    return this.hasPartner
      ? this.userHousing?.savings +
          this.userHousing.partnerSavings +
          this.userHousing.expectedMoney +
          this.userHousing.partnerExpectedMoney
      : this.userHousing?.savings + this.userHousing.expectedMoney;
  }

  get userProducts() {
    return this.getUserProducts();
  }

  get investmentProducts(): any {
    return this.getInvestmentProducts();
  }

  get userHousing(): IHousing {
    return this.getUserHousing();
  }

  get averageRateOfReturn(): number {
    return this.investmentProducts[0]?.averageRateOfReturn || 0;
  }

  get isCashAdded(): boolean {
    return this.cashLisaAmount > 0 || this.fixedRateAmount > 0 || this.easyAccessAmount > 0;
  }

  // get isSooner(): boolean {
  //   return this.timeSaved > 0;
  // }

  // get totalAmount(): number {
  //   let sum = 0;
  //   this.userProducts.forEach((product) => {
  //     sum += +product.projectedAmount;
  //   });
  //   return sum;
  // }

  get homeGoal(): number {
    return this.getCalculationHousePrice()?.deposit.homeGoal;
  }

  // get estimatedMoveInDate(): any {
  //   return this.getCalculationHousePrice()?.estimatedMoveInDate;
  // }

  get increasedDateFull(): string {
    const date = new Date(this.getIncreaseTimeEstimatedMoveInDate());
    const now = new Date();
    const diff = monthDiff(now, date);
    const yearDiff = Math.floor(diff / 12) > 0 ? Math.floor(diff / 12) + ' years ' : '';
    const monthsDiff = (diff % 12) + ' months left';
    return yearDiff + monthsDiff;
  }

  get currentSavings(): number {
    return this.getUserHousing()?.savings + this.getUserHousing()?.partnerSavings;
  }

  get monthlyContribution(): number {
    return this.getUserHousing()?.monthlyContribution + this.getUserHousing()?.partnerMonthlyContribution;
  }

  // get runningTotals(): IRunningTotals {
  //   return this.getRunningTotals();
  // }

  get initialSumRemaining(): number {
    return this.getRunningTotals()?.initialSumRemaining;
  }

  get monthlyAmount(): number {
    return this.getRunningTotals()?.monthlyAmountRemaining;
  }

  get timeSaved(): number {
    return this.getTimeSaved()?.timeSaved;
  }

  get monthsSaved(): string {
    const time = this.getTimeSaved()?.timeSaved;
    const years = Math.floor(time / 12);
    const yearLabel = years > 1 ? ' years ' : ' year ';
    const months = time % 12;
    const monthLabel = months > 1 ? ' months' : ' month';
    const monthsText = months === 0 ? '' : months + monthLabel;
    return years ? years + yearLabel + monthsText : monthsText;
  }

  get possibleMoveIn(): string {
    return dateMonths(this.getIncreaseTimeEstimatedMoveInDate(), this.timeSaved);
  }

  get moveInDate(): string {
    return dateMonths(this.getIncreaseTimeEstimatedMoveInDate());
  }

  get cashLisaAmount(): number {
    return this.cashLifeTimeProducts[0]?.projectedAmount || 0;
  }

  get fixedRateAmount(): number {
    let sum = 0;
    this.fixedRateProducts.forEach((product) => {
      sum += +product.projectedAmount;
    });
    return sum;
  }

  get fixedRateAmounts(): number[][] {
    const productsMap = new Map();
    const kinds = new Set();
    this.fixedRateProducts.forEach((product) => {
      if (kinds.has(product.fixedTermYears)) {
        const value = productsMap.get(product.fixedTermYears);
        productsMap.set(product.fixedTermYears, product.projectedAmount + value);
      } else {
        kinds.add(product.fixedTermYears);
        productsMap.set(product.fixedTermYears, product.projectedAmount);
      }
    });

    return Array.from(productsMap);
  }

  get easyAccessAmount(): number {
    let sum = 0;
    this.easyAccessProducts.forEach((product) => {
      sum += +product.projectedAmount;
    });
    return sum;
  }

  get userInvestmentProducts() {
    const itemsSet = new Set<number>();
    const items = new Map<number, number>();
    this.userProducts.forEach((element: IUserInvestmentsProduct) => {
      if (element.investmentProductId >= 8) {
        if (!itemsSet.has(element.investmentProductId)) {
          itemsSet.add(element.investmentProductId);
          items.set(element.investmentProductId, element.projectedAmount);
        } else {
          const value = items.get(element.investmentProductId) || 0;
          items.set(element.investmentProductId, element.projectedAmount + value);
        }
      }
    });
    return Array.from(items);
  }

  get totalProjectedValue(): number {
    let totalProjectedValue = 0;
    this.userProducts.forEach((element: IUserInvestmentsProduct) => {
      // if (element.investmentProductId >= 0) {
      totalProjectedValue += element.projectedAmount;
      // }
    });
    return totalProjectedValue;
  }

  get moveInYears() {
    return this.getMoveInYears();
  }

  get currentDateTime(): string {
    return new Date().toISOString();
  }

  get cashLifeTimeProducts() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.cashLifetimeISA;
    });
  }

  get easyAccessProducts() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.easyAccess;
    });
  }

  get cautiousPortfolioProducts() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.cautiousPortfolio;
    });
  }

  get cautiousPortfolioProductsShares() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.cautiousPortfolioShares;
    });
  }

  get steadyPortfolioProducts() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.steadyPortfolio;
    });
  }

  get steadyPortfolioProductsShares() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.steadyPortfolioShares;
    });
  }

  get boldPortfolioProducts() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.boldPortfolio;
    });
  }

  get boldPortfolioProductsShares() {
    return this.getUserProducts().filter((product) => {
      return product.investmentProductId === this.investmentProductType.boldPortfolioShares;
    });
  }

  get fixedRateProducts() {
    return this.getUserProducts().filter((product) => {
      return (
        product.investmentProductId === this.investmentProductType.fixedRateDeposit_1 ||
        product.investmentProductId === this.investmentProductType.fixedRateDeposit_2 ||
        product.investmentProductId === this.investmentProductType.fixedRateDeposit_3 ||
        product.investmentProductId === this.investmentProductType.fixedRateDeposit_4 ||
        product.investmentProductId === this.investmentProductType.fixedRateDeposit_5
      );
    });
  }

  get averageRateOfReturnFixed() {
    return this.investmentProducts.find((product) => {
      return +product.fixedTermYears === +this.fixedRateForm.fixedTermYears;
    }).averageRateOfReturn;
  }

  get possibleMoveInText(): string {
    return this.initialSumRemaining < 0 || this.monthlyAmount < 0 ? '-' : this.possibleMoveIn;
  }

  userProduct(id): any {
    return this.userProducts.find((product) => {
      return product.investmentProductId === id;
    });
  }

  toggleExpanded(element: string): void {
    this.expanded[element] = !this.expanded[element];
  }

  isExpanded(element: string): boolean {
    return this.expanded[element];
  }

  number = (rule, value, callback: (error?) => any): void => {
    if (value === '') {
      callback(new Error('This field is required'));
    }
    if (isNumber(value)) {
      callback();
    } else {
      callback(new Error('Enter a number'));
    }
  };

  rules = {
    initialSum: [
      {
        required: true,
        validator: this.number,
        trigger: 'change',
      },
    ],
    monthlyAmount: [
      {
        required: false,
        validator: this.number,
        trigger: 'change',
      },
    ],
  };

  mounted() {
    // this.getUserProductsAction();
    // console.log(this.investmentProducts);
    // console.log(this);
  }

  getInvestmentItemTypeName = (item): any => {
    const el = this.investmentProducts.find((element) => {
      return element.id === item;
    });

    return el?.typeOfProduct
      ?.replace('Stocks & Shares', 'S&S')
      ?.replace('Lifetime ISA', 'LISA')
      ?.replace('Portfolio', '');
  };

  isProductAdded(id: number): boolean {
    switch (id) {
      case this.investmentProductType.cashLifetimeISA: {
        const product = this.userProduct(this.investmentProductType.cashLifetimeISA);
        // console.log(product);
        if (!!product) {
          // console.log(product);
          this.cashLifetimeForm.initialSum = product.initialSum;
          this.cashLifetimeForm.monthlyAmount = product.monthlyAmount;
        }
      }
    }

    const product = this.userProducts.find((product: IUserInvestmentsProduct) => {
      return product.investmentProductId === id;
    });
    return !!product;
  }

  getProductById(productId) {
    const [{ id, type, calculationType, fixedTermYears, averageRateOfReturn }] = this.investmentProducts.filter(
      (product) => {
        return +product.id === +productId;
      },
    );
    return {
      investmentProductId: id,
      type,
      calculationType,
      fixedTermYears,
      averageRateOfReturn,
    };
  }

  getProductRate(id: number): number {
    return this.getProductById(id)?.averageRateOfReturn || 0;
  }

  refreshUserProducts() {
    this.$auth.getTokenSilently().then((token) => {
      const moveInYears = this.moveInYears;
      this.getUserProductsAction({ token, moveInYears });
      this.getUserHousingAction(token).then((userHousing: IHousing) => {
        userHousing['firstTimeBuyerState'] = this.getBuyerState(userHousing);
        this.getHousingCalculation({ userHousing, token }).then((calculation) => {
          this.getMortgageEstimatesAction({
            token: token,
            payload: {
              moveInMonths: calculation.increaseTime,
              finalLoan: this.finalLoan,
            },
          });
        });
      });
    });
  }

  onStartAddingCashLifetime(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['cashLifeTimeForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.cashLifetimeISA);
          const formData = {
            initialSum: this.cashLifetimeForm.initialSum,
            monthlyAmount: this.cashLifetimeForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.cashLifetimeError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.cashLifetimeError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['cashLifeTimeForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingFixedRate(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['fixedRateForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(1 + +this.fixedRateForm.fixedTermYears);
          // console.log(product);
          // console.log(1 + +this.fixedRateForm.fixedTermYears);
          const formData = {
            initialSum: +this.fixedRateForm.initialSum,
            fixedTermYears: +this.fixedRateForm.fixedTermYears,
            monthlyAmount: 0,
            investmentProductId: product.investmentProductId,
            creationDate: this.currentDateTime,
            type: product.type,
            calculationType: product.calculationType,
            averageRateOfReturn: product.averageRateOfReturn,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.fixedRateError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.fixedRateError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['fixedRateForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingEasyAccess(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['easyAccessForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.easyAccess);
          // console.log(product);
          const formData = {
            initialSum: this.easyAccessForm.initialSum,
            monthlyAmount: this.easyAccessForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.easyAccessError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.easyAccessError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['easyAccessForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingCautiousPortfolio(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['cautiousPortfolioForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.cautiousPortfolio);
          // console.log(product);
          const formData = {
            initialSum: this.cautiousPortfolioForm.initialSum,
            monthlyAmount: this.cautiousPortfolioForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.cautiousPortfolioError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.cautiousPortfolioError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['cautiousPortfolioForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingCautiousPortfolioShares(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['cautiousPortfolioSharesForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.cautiousPortfolioShares);
          // console.log(product);
          const formData = {
            initialSum: this.cautiousPortfolioSharesForm.initialSum,
            monthlyAmount: this.cautiousPortfolioSharesForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.cautiousPortfolioSharesError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.cautiousPortfolioSharesError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['cautiousPortfolioSharesForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingSteadyPortfolio(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['steadyPortfolioForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.steadyPortfolio);
          // console.log(product);
          const formData = {
            initialSum: this.steadyPortfolioForm.initialSum,
            monthlyAmount: this.steadyPortfolioForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.steadyPortfolioError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.steadyPortfolioError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['steadyPortfolioForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingSteadyPortfolioShares(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['steadyPortfolioSharesForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.steadyPortfolioShares);
          // console.log(product);
          const formData = {
            initialSum: this.steadyPortfolioSharesForm.initialSum,
            monthlyAmount: this.steadyPortfolioSharesForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.steadyPortfolioSharesError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.steadyPortfolioSharesError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['steadyPortfolioSharesForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingBoldPortfolio(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['boldPortfolioForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.boldPortfolio);
          // console.log(product);
          const formData = {
            initialSum: this.boldPortfolioForm.initialSum,
            monthlyAmount: this.boldPortfolioForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.boldPortfolioError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.boldPortfolioError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['boldPortfolioForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartAddingBoldPortfolioShares(): void {
    const moveInYears = this.moveInYears;
    this.$auth.getTokenSilently().then((token) => {
      this.$refs['boldPortfolioSharesForm']?.['validate']((valid) => {
        if (valid) {
          const product = this.getProductById(this.investmentProductType.boldPortfolioShares);
          // console.log(product);
          const formData = {
            initialSum: this.boldPortfolioSharesForm.initialSum,
            monthlyAmount: this.boldPortfolioSharesForm.monthlyAmount,
            creationDate: this.currentDateTime,
            ...product,
          };
          this.addUserInvestmentProduct({ token, formData, moveInYears })
            .then((response) => {
              if (response.error) {
                this.boldPortfolioSharesError = response.errorMessage;
                this.openErrorMessage(response.errorMessage);
              } else {
                this.boldPortfolioSharesError = null;
                this.openSuccessMessage();
              }
            })
            .finally(() => {
              this.refreshUserProducts();
              this.$refs['boldPortfolioSharesForm']?.['resetFields']();
            });
        }
      });
    });
  }

  onStartDeleteCashLifeTime(): void {
    const productId = this.userProducts.find((product: IUserInvestmentsProduct) => {
      return product.investmentProductId === this.investmentProductType.cashLifetimeISA;
    })?.id;
    this.$auth.getTokenSilently().then((token) => {
      this.deleteUserProduct({ token, productId })
        .then(() => {
          this.openSuccessMessageProductRemoved();
        })
        .finally(() => {
          this.refreshUserProducts();
        });
    });
  }

  onStartDeleteProduct(productId): void {
    this.$auth.getTokenSilently().then((token) => {
      this.deleteUserProduct({ token, productId })
        .then(() => {
          this.openSuccessMessageProductRemoved();
        })
        .finally(() => {
          this.refreshUserProducts();
        });
    });
  }

  openErrorMessage(message) {
    this.$message.loading({ content: 'Error updating scenario...', key: '2' });
    setTimeout(() => {
      this.$message.error({
        content: message,
        key: '2',
        duration: 5,
      });
    }, 100);
  }

  openSuccessMessage() {
    this.$message.loading({ content: 'Adding product to scenario...', key: '0' });
    setTimeout(() => {
      this.$message.success({
        content: 'Product added to scenario!',
        key: '0',
        duration: 2,
      });
    }, 100);
  }

  openSuccessMessageProductRemoved() {
    this.$message.loading({ content: 'Removing product from scenario...', key: '1' });
    setTimeout(() => {
      this.$message.success({
        content: 'Product removed from scenario!',
        key: '1',
        duration: 2,
      });
    }, 100);
  }
}
