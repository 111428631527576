export interface IState {
  app: {
    menuCollapsed: boolean;
    userDataLoaded: boolean;
  };
  data: {
    userHousingLoaded: boolean;
    calculations: {
      housePrice: ICalculationHousePrice;
    };
    userHousing: IHousing | null;
    userInvestmentsProducts: IUserInvestmentsProducts | null;
    investmentsProducts: any[];
    mortgageEstimates: IMortgageEstimates | null;

  };
  user: IUser | null;
  userClaims: any;
  userToken: null | string;
}

export interface ICalculationHousePriceDeposit {
  depositBeforeFees: number;
  depositWithFees: number;
  stampDuty: number;
  legalFees: number;
  mortgageFees: number;
  surveyorCosts: number;
  monthlyDepositSaving: number;
  monthlyContribution: number;
  deposit: number;
  homeGoal: number;
}

export interface ICalculationHousePrice {
  deposit: ICalculationHousePriceDeposit;
  estimatedMoveInDate: any;
  futureHousePrice: number;
  affordableHomePrice: number;
  depositPercentage: number;
  lisaAmount: number;
  finalLoan: number;
  savings: number;
  yearlyEarnings: number;
  partnerSavings: number;
  partnerYearlyEarnings: number;
  futureWindfall: number;
  increaseTimeEstimatedMoveInDate: null | string;
  increaseTime: number;
}

export interface Option {
  text: string;
  value: number;
}

export interface IMortgageEstimates {
  mrl: number;
  mru: number;
  mpl: number;
  mpu: number;
  fl: number;
  mt: number;
}

export interface IUser {
  created_at?: string;
  email?: string;
  email_verified?: boolean;
  identities?: Array<any>;
  name?: string;
  nickname?: string;
  password?: string;
  picture?: string;
  updated_at?: string;
  user_id?: string;
  user_metadata?: IUserMetadata;
}

export interface IUserMetadata {
  address_city?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_postcode?: string;
  address_residence?: string;
  day_of_birth?: string;
  last_name?: string;
  month_of_birth?: string;
  name?: string;
  sex?: string;
  telephone?: string;
  year_of_birth?: string;
}

export interface IHousing {
  id: number;
  depositGoal: number;
  targetMoveInDate: string;
  targetMoveInMonths: number;
  affordableHomePrice: number;
  monthlyContribution: number;
  savings: number;
  yearlyEarnings: number;
  houseRegion: string;
  firstTimeBuyer: true;
  firstTimeBuyerState: string;
  dreamAreaJson: string | Record<string, unknown>;
  userUid: string;
  expectedMoney: number;
  lisaContribution: number;
  lisaEnabled: true;
  additionalHouse: true;
  hasPartner: true;
  partnerSavings: number;
  partnerYearlyEarnings: number;
  partnerMonthlyContribution: number;
  partnerExpectedMoney: number;
  thinkingFirstTimeBuyer: boolean;
  switchingHome: boolean;
}

export interface IInvestmentProduct {
  investmentProductInitialSum?: number;
  investmentProductMonthlyAmount?: number;
  investmentProductProjectedAmount?: number;
  id: number;
  type: number;
  calculationType: number;
  typeOfProduct: string;
  fixedTermYears: number;
  averageRateOfReturn: number;
}

export interface IUserInvestmentsProduct {
  projectedAmount: number;
  id: number;
  userUid: string;
  investmentProductId: number;
  initialSum: number;
  monthlyAmount: number;
  creationDate: any;
  type: number;
  calculationType: number;
  fixedTermYears: number;
  averageRateOfReturn: number;
}

export interface IUserInvestmentsProducts {
  timeSaved: ITimeSaved;
  runningTotals: IRunningTotals;
  investmentProducts: IInvestmentProduct[];
  userInvestmentProducts: IUserInvestmentsProduct[];
}

export interface IRunningTotals {
  totalInitialSum: number;
  totalMonthlyAmount: number;
  totalProjectedAmount: number;
  initialSumRemaining: number;
  monthlyAmountRemaining: number;
}

export interface ITimeSaved {
  error: boolean;
  errorMessage: string | null;
  timeSaved: number;
  possibleMoveIn: number;
}

export interface IDreamArea {
  bedrooms: number;
  postCode: string;
  price: number;
  savingJourneyOption: number;
  propertyType: string;
}

export enum InvestmentProductType {
  cashLifetimeISA = 1,
  fixedRateDeposit_1 = 2,
  fixedRateDeposit_2 = 3,
  fixedRateDeposit_3 = 4,
  fixedRateDeposit_4 = 5,
  fixedRateDeposit_5 = 6,
  easyAccess = 7,
  cautiousPortfolio = 8,
  steadyPortfolio = 9,
  boldPortfolio = 10,
  cautiousPortfolioShares = 11,
  steadyPortfolioShares = 12,
  boldPortfolioShares = 13,
}


export const SAVING_JOURNEY_OPTIONS = new Map([
  [0, ['Not Selected', 'Not Selected']],
  [1, ['Thinking about it', 'Thinking about it']],
  [2, ['Saving for my first home', 'Both of us saving for our first home']],
  [3, ['Switching my home', 'One/both of us switching a home']],
  [4, ['Saving for an additional home', 'One/both of us saving for an additional home']],
]);
