import {
  ICalculationHousePrice,
  IDreamArea,
  IHousing,
  IMortgageEstimates,
  IRunningTotals,
  IState,
  ITimeSaved,
  IUser,
} from '@/app/models';
import {
  GET_ACTUAL_DEPOSIT,
  GET_CALCULATION_HOUSE_PRICE,
  GET_DREAM_AREA,
  GET_ESTIMATED_MOVE_IN_DATE,
  GET_FINAL_LOAN,
  GET_GOAL_DATASET,
  GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE,
  GET_INVESTMENT_PRODUCTS,
  GET_MORTGAGE_ESTIMATES,
  GET_MOVE_IN_DATE,
  GET_MOVE_IN_YEARS,
  GET_RUNNING_TOTALS,
  GET_TIME_SAVED,
  GET_USER_CLAIMS,
  GET_USER_HOUSING,
  GET_USER_HOUSING_LOADED,
  GET_USER_INVESTMENT_PRODUCTS,
  GET_USER_METADATA,
  GET_USER_METADATA_LOADED,
  GET_USER_TOKEN,
  MENU_MOBILE_COLLAPSED,
} from '@/app/store/getters/getter.types';

export default {
  [MENU_MOBILE_COLLAPSED]: (state: IState): boolean => {
    return state.app.menuCollapsed;
  },
  [GET_CALCULATION_HOUSE_PRICE]: (state: IState): ICalculationHousePrice | null => {
    return state.data.calculations.housePrice;
  },
  [GET_ACTUAL_DEPOSIT]: (state: IState): number | null => {
    return state.data.calculations.housePrice.deposit?.deposit || null;
  },
  [GET_GOAL_DATASET]: (state: IState): number[] => {
    if (!state.data.calculations.housePrice.deposit) {
      return [0, 0, 0, 0];
    }
    return [
      state.data.calculations.housePrice.deposit.deposit,
      state.data.calculations.housePrice.deposit.stampDuty,
      state.data.calculations.housePrice.deposit.legalFees,
      state.data.calculations.housePrice.deposit.mortgageFees +
        state.data.calculations.housePrice.deposit.surveyorCosts,
    ];
  },
  [GET_USER_HOUSING]: (state: IState): IHousing | null => {
    return state.data.userHousingLoaded ? state.data.userHousing : null;
  },
  [GET_USER_METADATA]: (state: IState): IUser | null => {
    return state.app.userDataLoaded ? state.user : null;
  },
  [GET_USER_METADATA_LOADED]: (state: IState): boolean => {
    return state.app.userDataLoaded;
  },
  [GET_USER_HOUSING_LOADED]: (state: IState): boolean => {
    return state.data.userHousingLoaded;
  },
  [GET_USER_CLAIMS]: (state: IState): string => {
    return state.userClaims;
  },
  [GET_USER_TOKEN]: (state: IState): string | null => {
    return state.userToken;
  },
  [GET_USER_INVESTMENT_PRODUCTS]: (state: IState): any[] => {
    return state.data.userInvestmentsProducts?.userInvestmentProducts || [];
  },
  [GET_RUNNING_TOTALS]: (state: IState): IRunningTotals | null => {
    return state.data.userInvestmentsProducts?.runningTotals || null;
  },
  [GET_TIME_SAVED]: (state: IState): ITimeSaved | null => {
    return state.data.userInvestmentsProducts?.timeSaved || null;
  },
  [GET_INVESTMENT_PRODUCTS]: (state: IState): any => {
    return state.data.investmentsProducts;
  },
  [GET_MOVE_IN_DATE]: (state: IState): any => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const targetMoveInDate = state.data.calculations.housePrice.increaseTimeEstimatedMoveInDate;
    if (typeof targetMoveInDate === 'string') {
      const date = new Date(targetMoveInDate);
      return monthNames[date.getMonth()] + ' ' + date.getFullYear();
    }
    return null;
  },
  [GET_MOVE_IN_YEARS]: (state: IState): number | null => {
    const date = state.data.calculations.housePrice.increaseTimeEstimatedMoveInDate;
    if (typeof date === 'string') {
      const now = new Date().getTime();
      const fDate = new Date(new Date(date).getTime() - now);
      const decimalMonths = +(fDate.getMonth() / 12);
      return Math.abs(fDate.getFullYear() - 1970) + decimalMonths;
    }
    return null;
  },
  [GET_DREAM_AREA]: (state: IState): IDreamArea | null => {
    const dreamAreaJson = state.data.userHousing?.dreamAreaJson;
    if (!dreamAreaJson) return null;
    return JSON.parse(dreamAreaJson as string);
  },
  [GET_MORTGAGE_ESTIMATES]: (state: IState): IMortgageEstimates | null => {
    return state.data.mortgageEstimates;
  },
  [GET_FINAL_LOAN]: (state: IState): number | null => {
    // console.log(state.data.calculations.housePrice.finalLoan);
    return state.data.calculations.housePrice.finalLoan;
  },
  [GET_INCREASE_TIME_ESTIMATED_MOVE_IN_DATE]: (state: IState): string | null => {
    return state.data.calculations.housePrice.increaseTimeEstimatedMoveInDate;
  },
  [GET_ESTIMATED_MOVE_IN_DATE]: (state: IState): string | null => {
    return state.data.calculations.housePrice.estimatedMoveInDate;
  },
};
