import {
  GET_CALCULATION_HOUSE_PRICE_ACTION,
  GET_USER_HOUSING_ACTION,
  GET_USER_METADATA_ACTION,
  SET_USER_LOADED_ACTION,
  TOGGLE_MOBILE_MENU_COLLAPSE_ACTION,
  SET_USER_METADATA_ACTION,
  SET_USER_CLAIMS_ACTION,
  GET_USER_TOKEN_ACTION,
  CLOSE_ACCOUNT_ACTION,
  GET_USER_INVESTMENT_PRODUCTS_ACTION,
  ADD_USER_INVESTMENT_PRODUCT_ACTION,
  DELETE_USER_INVESTMENT_PRODUCT_ACTION,
  GET_MORTGAGE_ESTIMATES_ACTION,
  UPDATE_SAVINGS_ACTION,
  GET_INVESTMENT_PRODUCTS_ACTION,
} from '@/app/store/actions/action.types';
import {
  GET_CALCULATION_HOUSE_PRICE_MUTATION,
  TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION,
  GET_USER_HOUSING_MUTATION,
  GET_USER_METADATA_MUTATION,
  SET_USER_LOADED_MUTATION,
  SET_USER_CLAIMS_MUTATION,
  GET_USER_TOKEN_MUTATION,
  SET_USER_INVESTMENTS_PRODUCTS_MUTATION,
  SET_MORTGAGE_ESTIMATES_MUTATION,
  GET_USER_HOUSING_LOADED_MUTATION,
  GET_INVESTMENT_PRODUCTS_MUTATION,
} from '@/app/store/mutations/mutation.types';
import { ActionContext } from 'vuex';
import api from '@habstash/habstash-frontend-api/lib/api';
import { AxiosResponse } from 'axios';
import {
  OwnerPayload,
  PartnerPayload,
} from '@habstash/habstash-frontend-api/lib/api/repositories/calculations-repository/reverse-house-price/types';
import { IHousing } from '@/app/models';
import { monthInDate } from '@/app/utils/date_helpers';

export default {
  [TOGGLE_MOBILE_MENU_COLLAPSE_ACTION](context: ActionContext<unknown, unknown>, payload: boolean): void {
    context.commit(TOGGLE_MOBILE_MENU_COLLAPSE_MUTATION, payload);
  },

  async [GET_CALCULATION_HOUSE_PRICE_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      userHousing: Partial<IHousing & OwnerPayload & PartnerPayload>,
      token,
    },
  ): Promise<unknown> {
    console.log(user);
    const housing = user.userHousing;
    console.log(housing);
    console.log(JSON.parse(<string>user.userHousing.dreamAreaJson).price);

    const dreamHomePrice = await JSON.parse(<string>user.userHousing.dreamAreaJson).price;
    return await api.calculations
      .reverseCalculateHousePrice(
        {
          dreamHomePrice: dreamHomePrice,
          expectedMoney: user.userHousing.expectedMoney,
          moveInMonths: monthInDate(user.userHousing.targetMoveInDate),
          savings: user.userHousing.savings,
          monthlyContribution: user.userHousing.monthlyContribution,
          yearlyEarnings: user.userHousing.yearlyEarnings,
          hasPartner: user.userHousing.hasPartner,
          houseRegion: user.userHousing.houseRegion,
          firstTimeBuyerState: user.userHousing.firstTimeBuyerState,
        } as OwnerPayload,
        {
          partnerSavings: user.userHousing.partnerSavings,
          partnerMonthlyContribution: user.userHousing.partnerMonthlyContribution,
          partnerYearlyEarnings: user.userHousing.partnerYearlyEarnings,
          partnerExpectedMoney: user.userHousing.partnerExpectedMoney,
        } as PartnerPayload,
        user.token,
      )
      .then((data: AxiosResponse) => {
        context.commit(GET_CALCULATION_HOUSE_PRICE_MUTATION, data.data);
        return data.data;
      })
      .catch((error: Error) => {
        console.error(error);
      });
  },

  async [GET_USER_HOUSING_ACTION](context: ActionContext<unknown, unknown>, token: string): Promise<unknown> {
    return await api.housing
      .get(token)
      .then((data) => {
        context.commit(GET_USER_HOUSING_MUTATION, data.data);
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [GET_USER_METADATA_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      userId: string;
    },
  ): Promise<unknown> {
    return await api.auth
      .getUserData(user.token, user.userId)
      .then((data) => {
        context.commit(GET_USER_METADATA_MUTATION, data.data);
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [SET_USER_METADATA_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      userId: string;
      payload: any;
    },
  ): Promise<unknown> {
    return await api.auth
      .updateUserData({
        token: user.token,
        userId: user.userId,
        payload: user['formData'],
      })
      .then((data) => {
        context.commit(GET_USER_METADATA_MUTATION, data.data.data);
        return data.data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [SET_USER_LOADED_ACTION](context: ActionContext<unknown, unknown>, payload: boolean): Promise<unknown> {
    return context.commit(SET_USER_LOADED_MUTATION, payload);
  },

  async [SET_USER_CLAIMS_ACTION](context: ActionContext<unknown, unknown>, payload: any): Promise<unknown> {
    return context.commit(SET_USER_CLAIMS_MUTATION, payload);
  },

  async [CLOSE_ACCOUNT_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      userId: string;
    },
  ): Promise<unknown> {
    return await api.auth
      .deleteUser({
        token: user.token,
        userId: user.userId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [GET_USER_TOKEN_ACTION](context: ActionContext<unknown, unknown>): Promise<unknown> {
    return await api.auth
      .getToken()
      .then((data) => {
        context.commit(GET_USER_TOKEN_MUTATION, data);
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [GET_USER_INVESTMENT_PRODUCTS_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      moveInYears: string;
    },
  ): Promise<unknown> {
    return api.investmentsProducts
      .userInvestmentsProducts(user.token, user.moveInYears)
      .then((data) => {
        context.commit(SET_USER_INVESTMENTS_PRODUCTS_MUTATION, data.data);
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [ADD_USER_INVESTMENT_PRODUCT_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      formData: any;
      moveInYears: number;
    },
  ): Promise<unknown> {
    return api.investmentsProducts
      .investmentProduct(user.token, user.formData, user.moveInYears)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [DELETE_USER_INVESTMENT_PRODUCT_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      productId: number;
    },
  ): Promise<unknown> {
    return api.investmentsProducts
      .deleteInvestmentProduct(user.token, user.productId)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [GET_MORTGAGE_ESTIMATES_ACTION](
    context: ActionContext<unknown, unknown>,
    user: {
      token: string;
      payload: {
        moveInMonths: number;
        finalLoan: number;
      };
    },
  ): Promise<unknown> {
    return api.mortgageEstimates
      .calculateMortgageEstimates(user.token, user.payload)
      .then((data) => {
        context.commit(SET_MORTGAGE_ESTIMATES_MUTATION, data.data);
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  async [UPDATE_SAVINGS_ACTION](
    context: ActionContext<unknown, unknown>,
    payload: {
      token: string;
      data: {
        moveInMonths: number;
        finalLoan: number;
      };
      id: number;
    },
  ): Promise<unknown> {
    context.commit(GET_USER_HOUSING_LOADED_MUTATION, false);
    return api.housing.progress(payload.token, payload.data, payload.id).then((data) => {
      if (data.status === 200) {
        context.commit(GET_USER_HOUSING_LOADED_MUTATION, true);
        return data;
      }
    });
  },

  async [GET_INVESTMENT_PRODUCTS_ACTION](context: ActionContext<unknown, unknown>): Promise<unknown> {
    return api.investmentsProducts.investmentsProducts().then((data) => {
      context.commit(GET_INVESTMENT_PRODUCTS_MUTATION, data);
      return data;
    });
  },
};
