export const CURRENCY_SYMBOL = '£';

const currencyFormatter = (value: any, rounding = 1): string => {
  const currency = value === '' || isNaN(+value) ? (value as string) : Math.round(+value / rounding) * rounding;
  return `${CURRENCY_SYMBOL}${currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const currencyParser = (value) => {
  return value.replace(/£\s?|(,*)/g, '');
};

export { currencyFormatter, currencyParser };
