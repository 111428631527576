import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mainRoutes } from '@/app/router/routes';
import { RouteConfig } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import { TOGGLE_MOBILE_MENU_COLLAPSE_ACTION } from '@/app/store/actions/action.types.ts';
import { MENU_MOBILE_COLLAPSED } from '@/app/store/getters/getter.types';

@Component({
  components: {},
  methods: {
    ...mapActions({
      toggleMenuCollapseAction: TOGGLE_MOBILE_MENU_COLLAPSE_ACTION,
    }),
    ...mapGetters({
      menuCollapsed: MENU_MOBILE_COLLAPSED,
    }),
  },
})
export default class SidebarMenu extends Vue {
  @Prop() collapsed!: boolean;

  routes: RouteConfig[] = [];
  activeRouteName?: string;
  activeRoutePath?: string;
  private toggleMenuCollapseAction!: (collapsed: boolean) => void;
  private menuCollapsed!: () => boolean;

  @Watch('$route')
  onChanges(): void {
    this.activeRouteName = this.currentRouteName();
    if (this.menuCollapsed()) {
      this.toggleMenuCollapseAction(false);
    }
    this.$forceUpdate();
  }

  created(): void {
    this.routes = mainRoutes;
    this.activeRouteName = this.currentRouteName();
    this.activeRoutePath = this.$route.path;
  }

  isRouteActive(route: RouteConfig): boolean {
    return route.name === this.activeRouteName;
  }

  currentRouteName(): string {
    return this.$route.name || '';
  }
}
