import i18n from '@/app/i18n';

export const dateInMonths = (numberOfMonths = 1): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + numberOfMonths, 1);
};

export const periodInWords = (numberOfMonths = 1): string => {
  const months = numberOfMonths % 12;
  const years = Math.floor(numberOfMonths / 12);
  const yearsLabel = i18n.tc('years_count', years, {
    count: years,
  });
  const monthsLabel = i18n.tc('months_count', months, {
    count: months,
  });

  if (months === 0) {
    return yearsLabel;
  } else if (years === 0) {
    return monthsLabel;
  } else {
    return `${yearsLabel} ${i18n.tc('and')} ${monthsLabel}`;
  }
};

export const monthInDate = (date?): number => {
  const now = new Date();
  const _date = date ? new Date(date) : now;
  return (_date.getFullYear() - now.getFullYear()) * 12 + (_date.getMonth() - now.getMonth());
};

export const dateShortFormat = (datetime: Date): string => {
  const year = datetime.getFullYear();
  const monthName = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(datetime);

  return `${monthName} ${year}`;
};

export const dateShortFutureFormat = (numberOfMonths = 0): string => {
  const monthsToAdd = numberOfMonths % 12;
  const yearsToAdd = Math.floor(numberOfMonths / 12);
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const datetime = new Date(year + yearsToAdd, month + monthsToAdd, 1, 0, 0, 0, 0);
  const monthName = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(datetime);
  return `${monthName} ${datetime.getFullYear()}`;
};

export const monthDiff = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const dateMonths = (dateString: string, months = 0) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (months !== 0) {
    const date = new Date(new Date(dateString).setMonth(new Date(dateString).getMonth() - months));
    return monthNames[date.getMonth()] + ' ' + date.getFullYear();
  }
  const date = new Date(dateString);
  return monthNames[date.getMonth()] + ' ' + date.getFullYear();
};
