import { Component, Prop, Vue } from 'vue-property-decorator';
import { currencyFormatter } from '@/app/utils/currencyFormatter';
import DoughnutChart from '../DoughnutChart/DoughnutChart.vue';
import chartColors from '../../pages/DashboardPage/chart-colors.module.scss';
import LegendItem from '../LegendItem/LegendItem.vue';

const CHART_COLORS = Object.freeze([
  chartColors.depositColor,
  chartColors.stampDutyColor,
  chartColors.legalFeesColor,
  chartColors.mortgageColor,
]);

@Component({
  name: 'review-chart',
  components: { DoughnutChart, LegendItem },
})
export default class ReviewChart extends Vue {
  @Prop({ type: Array, required: true })
  dataset!: Array<number>;

  get labels(): string[] {
    return [];
  }

  get chartData(): Record<string, unknown> {
    return {
      labels: this.labels,
      datasets: [
        {
          backgroundColor: CHART_COLORS,
          borderWidth: 0,
          weight: 2,
          data: this.dataset,
        },
      ],
    };
  }

  get legendItems(): Array<Record<string, unknown>> {
    return this.dataset.map((value: number) => {
      return {
        value: currencyFormatter(value),
        concern: this.labels,
        color: CHART_COLORS,
      };
    });
  }

  get targetSum(): string {
    const sum: number = this.dataset.reduce((a: number, b: number) => a + b, 0);
    return currencyFormatter(sum, 100);
  }
}
