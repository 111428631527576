import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// TODO: consider turning it into global app config
const LOCALE_EN_GB = 'en-GB';
const config = {
  i18n: {
    defaultLocale: LOCALE_EN_GB,
  },
};

const defaultLocale = config.i18n.defaultLocale || LOCALE_EN_GB;
// const messages = require(`./resources/i18n/${defaultLocale}.json`)[
//   defaultLocale
// ];

const i18n = new VueI18n({
  locale: defaultLocale,
  // messages: {
  //   [defaultLocale]: messages,
  // },
});

export default i18n;
