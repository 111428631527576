import { Component, Vue } from 'vue-property-decorator';
import { SidebarLogo, SidebarMenu } from '@/app/views/components';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_CALCULATION_HOUSE_PRICE_ACTION,
  GET_USER_HOUSING_ACTION,
  GET_USER_METADATA_ACTION,
  GET_USER_TOKEN_ACTION,
  SET_USER_CLAIMS_ACTION,
  SET_USER_LOADED_ACTION,
  TOGGLE_MOBILE_MENU_COLLAPSE_ACTION,
  GET_USER_INVESTMENT_PRODUCTS_ACTION,
} from '@/app/store/actions/action.types.ts';
import { GET_MOVE_IN_YEARS, GET_USER_HOUSING, MENU_MOBILE_COLLAPSED } from '@/app/store/getters/getter.types.ts';
import { useAuth0 as VueAuth } from '@/app/auth/authWrapper';
import { IHousing } from '@/app/models';

@Component({
  components: { SidebarMenu, SidebarLogo },
  methods: {
    ...mapActions({
      toggleMenuCollapseAction: TOGGLE_MOBILE_MENU_COLLAPSE_ACTION,
      getUserHousing: GET_USER_HOUSING_ACTION,
      getUserMetadataAction: GET_USER_METADATA_ACTION,
      setUserLoaded: SET_USER_LOADED_ACTION,
      setUserClaimsAction: SET_USER_CLAIMS_ACTION,
      getUserTokenAction: GET_USER_TOKEN_ACTION,
      getUserProductsAction: GET_USER_INVESTMENT_PRODUCTS_ACTION,

    }),
    ...mapGetters({
      menuCollapsed: MENU_MOBILE_COLLAPSED,
      userHousing: GET_USER_HOUSING,
      getMoveInYears: GET_MOVE_IN_YEARS,
    }),
  },
})
export default class SidebarLayout extends Vue {
  sidebarCollapsed = false;
  toggleMenuCollapseAction!: (collapsed: boolean) => void;
  getUserHousing!: (token: string) => any;
  menuCollapsed!: () => boolean;
  setUserLoaded!: (loaded: boolean) => boolean;
  userHousing!: () => IHousing;
  setUserClaimsAction!: (claims: any) => any;
  dashboardLoaded = false;

  private $auth: VueAuth;

  constructor() {
    super();
  }

  login(): void {
    this.$auth.loginWithRedirect();
  }

  logout(): void {
    this.$auth.logout();
  }

  get user(): any {
    return this.$auth.user;
  }

  get routeName(): string {
    return this.$route.name || '';
  }

  get notificationsNumber(): number {
    return 0;
  }

  get isMenuCollapsed(): boolean {
    return this.menuCollapsed();
  }

  get isAuthenticated(): boolean {
    return this.$auth.isAuthenticated;
  }

  toggleSidebarCollapse(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  toggleMenuCollapse(): void {
    this.setMenuCollapsed(!this.isMenuCollapsed);
  }

  touchHandlerSwipeLeft(): void {
    if (this.menuCollapsed()) this.setMenuCollapsed(false);
  }

  setMenuCollapsed(collapsed: boolean): void {
    this.toggleMenuCollapseAction(collapsed);
  }
}
